import React, { useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.min.css"

export default function Form() {
  const notify = () => {
    toast("Message Sent!")
  }
  var [submitted, setSubmitted] = useState(false)

  const recaptchaRef = React.useRef()

  const onSubmitWithReCAPTCHA = async event => {
    event.preventDefault()
    setSubmitted(true)
    notify()

    const token = await recaptchaRef.current.executeAsync()
    const { name, email, attorneys, message } = await event.target
    const endpoint =
      "https://29kkku42l7.execute-api.us-east-1.amazonaws.com/default/myLambda"

    const body = JSON.stringify({
      senderName: name.value,
      senderEmail: email.value,
      message: message.value,
      attorney: attorneys.value,
      token: token,
    })

    const requestOptions = {
      method: "POST",
      body,
    }

    await fetch(endpoint, requestOptions)
      .then(response => {
        if (!response.ok) throw new Error("Error in fetch")
        return response.json()
      })
      .then(response => {
        return response.data
      })
  }

  return (
    <form onSubmit={onSubmitWithReCAPTCHA}>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
      />
      <ToastContainer />
      <p>
        <label for="name">
          Your Name (required) <br />
          <input type="text" id="name" name="name" required />
        </label>
      </p>
      <p>
        <label for="email">
          Your Email (required) <br />
          <input type="email" id="email" name="email" required />
        </label>
      </p>
      <p>
        <label for="attorneys">
          Select an Attorney to Contact (required) <br />
          <select name="attorneys" id="attorneys">
            <option value="pft">Paul F. Tecklenburg</option>
            <option value="rtj">Rivers T. Jenkins, III</option>
            <option value="cej">Colleen E. Jacoby</option>
          </select>
        </label>
      </p>
      <p>
        <label for="message">
          Subject (required)
          <br />
          <textarea
            name="message"
            id="message"
            cols="30"
            rows="10"
            required
          ></textarea>
        </label>
      </p>
      <input type="submit" value="Submit" name="submit" disabled={submitted} />
      <ToastContainer />
    </form>
  )
}
