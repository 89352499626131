import { GatsbySeo } from "gatsby-plugin-next-seo"
import React from "react"
import Layout from "../components/Layout"
import Form from "../components/Form"
import * as styles from "../styles/contact.module.css"

export default function Contact() {
  return (
    <Layout>
      <GatsbySeo
        title="Contact | Tecklenburg, Jenkins & Jacoby, LLC | Charleston, SC"
        description="Contact Tecklenburg, Jenkins & Jacoby now for your legal needs. Please keep in mind that sending an email will not establish an
            attorney-client relationship. Tecklenburg, Jenkins & Jacoby, LLC must
            determine whether there is any conflict of interest, and must enter
            a written retention agreement acceptable to all parties before a
            relationship exists."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/contact/"
      />
      <section className={styles.content}>
        <div className={styles.contact}>
          <h1>Contact Tecklenburg, Jenkins & Jacoby</h1>
          <p>
            Please keep in mind that sending an email will not establish an
            attorney-client relationship. Tecklenburg, Jenkins & Jacoby, LLC
            must determine whether there is any conflict of interest, and must
            enter a written retention agreement acceptable to all parties before
            a relationship exists. Accordingly, please do not send any documents
            or confidential information until a formal attorney-client
            relationship has been established through a meeting with the law
            firm. Any information or documents sent prior to your receipt of a
            retention letter cannot be treated as confidences or protected
            information of any nature. Clicking "Submit" acknowledges that you
            agree with this notice.
          </p>
          <Form />
        </div>
        <div>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3356.7630861328926!2d-79.96789404874376!3d32.71892258089637!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88fe78ffbcc7e16d%3A0xb5a050cacd239d50!2s1250%20Folly%20Rd%2C%20Charleston%2C%20SC%2029412!5e0!3m2!1sen!2sus!4v1620001139501!5m2!1sen!2sus"
            width="500"
            height="350"
            style={{ border: 0 }}
            allowfullscreen=""
            loading="lazy"
            title="google maps location"
          ></iframe>
          <p>
            Tecklenburg, Jenkins & Jacoby, LLC <br />
            1250 Folly Rd <br />
            Charleston, SC 29412 <br /> Phone: (843) 534-2628 <br />
            Facsimile: (843) 534-2629
          </p>
        </div>
      </section>
    </Layout>
  )
}
